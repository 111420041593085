import { MultiAutocompleteField  } from '@jsluna/autocomplete'
import { useComponentContext } from '../../ComponentContext'
import { IDropdown } from '../../../../../types/ComponentTypes/Components'
import { useEffect, useState } from 'react'

function MultiAutocompleteComponent({ label, options, placeholder, id, error, answer, defaultValue }: IDropdown) {
  const [value, setValue] = useState(defaultValue ? JSON.parse(defaultValue) || [] : JSON.parse(answer) || [])
  const { handleUpdate } = useComponentContext()
  const handleSelect = (e: any) => {
    handleUpdate({id, value: JSON.stringify(e)})
    setValue(e.value)
  }

  useEffect(() => {
    if (value) {
      handleUpdate({id, value: JSON.stringify(value)})
    }
  }, [value]);

  return (
    
    <div id="s-reporting-multi-autocomplete" tabIndex={-1}>
      <MultiAutocompleteField 
        name={id}
        placeholder={placeholder}
        label={label}
        options={options}
        onSelect={handleSelect}
        error={error}
        defaultSelectedOptions={value}
        role="search"
      />
    </div>
  )
}

export default MultiAutocompleteComponent
