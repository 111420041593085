import { FilledButton } from '@jsluna/button'

function TryAgainButton({ tryAgain }: any) {
  return <FilledButton onClick={tryAgain}>Try Again</FilledButton>
}

function Error({ message, tryAgain }: any ) {
  return (
    <div className="s-page-push ln-u-text-align-center ln-u-vertical-align-center" id="s-error-page">
      <div>{message}</div>
      <br />
      {tryAgain && <TryAgainButton tryAgain={tryAgain} />}
    </div>
  )
}

export default Error
