import { useState, useEffect } from 'react'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import { Alert } from '@jsluna/react'
import Error from '../components/Error'
import ComponentSwitch from '../components/Reporting/ComponentSwitch'
import getAvailableComponents from '../components/Reporting/ComponentSwitch/getAvailableComponents'
import ReportingErrorBoundary from '../components/Reporting/ReportingErrorBoundary'
import { ComponentContextProvider } from '../components/Reporting/ComponentSwitch/ComponentContext'
import Loading from '../components/Loading'
import useCurrentStore from '../hooks/useCurrentStore'
import useReportingReducer, { back, cancel, next, resetMetadata, save, init, updateStoreCode, updateAnswer, updateMetadata } from '../hooks/useReportingReducer'
import { useApiClient } from '../context/AppContext'
import { useNavigate } from "react-router"

const ReportingPage = () => {
  const store = useCurrentStore()
  const apiClient = useApiClient()
  const [nextDisabledCounter, setNextDisabledCounter] = useState(0)
  const [formSaved, setFormSaved] = useState(false)
  const [state, dispatch] = useReportingReducer(apiClient)
  const navigateTo = useNavigate()

  useEffect(() => {
    if (store.storeCode) {
      dispatch(updateStoreCode(store.storeCode))
    }
  }, [store.storeCode])

  useEffect(() => {
    const sessionId = sessionStorage.getItem('draftId') || null
    const investigationId = sessionStorage.getItem('investigationId') || null
    dispatch(init(sessionId, investigationId))
  }, [])

  useEffect(() => {
    if (state.sessionId) {
      sessionStorage.setItem('draftId', state.sessionId)
    }
  }, [state.sessionId])

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    };
  }, [])

  useEffect(() => {
    if (state.loading) {
      disableNext()
    } else {
      enableNext()
    }
  }, [state.loading])

  const handleGetAnswer = ({ id }: any) => {
    return state.answers[id]
  }

  const handleGetAnswers = ({ids}: any) => {
    return ids.map((id:any) => state.answers[id])
  }

  const handleUpdate = ({id,value}:{id:any,value:any}) => {
    dispatch(updateAnswer(id , value))
    setFormSaved(false)
  }

  const handleNext = (overrides: any) => {
    dispatch(next(overrides))
    if (isSaved()) {
      setFormSaved(false)
    }
  }

  const handleUpdateMetadata = ({id,value}:{id:any,value:any}) => {
    dispatch(updateMetadata(id, value))
  }

  const handleResetMetadata = () => {
    dispatch(resetMetadata())
  }

  const handleCancel = () => {
    dispatch(cancel())
  }

  const handleBack = () => {
    dispatch(back())
    if (isSaved()) {
      setFormSaved(false)
    }
  }

  const disableNext = () => {
    setNextDisabledCounter(count => count + 1)
  }

  const enableNext = () => {
    setNextDisabledCounter(count => count - 1)
  }

  const isNextDisabled = () => nextDisabledCounter > 0

  const handleSave = () => {
    dispatch(save())
    setFormSaved(true)
  }

  const isSaved = () => formSaved

  const handleBeforeUnload = () => {
    if (!isSaved()) {
      handleSave()
    }
  }

  if (!state?.components && state.loading) return <Loading />

  if (state.error) {
    return (
      <Container className="s-page-push ln-u-text-align-center ln-u-vertical-align-center">
        <Error
          message={state?.error?.message || 'An error has occurred'}
          tryAgain={() => window.location.reload()}
        />
      </Container>
    )
  }
  return (
    <Container size="xs" className="s-page-push">
      <ReportingErrorBoundary>
        <ComponentContextProvider
          availableComponents={getAvailableComponents()}
          answers={state.answers || {}}
          handleNext={handleNext}
          handleUpdate={handleUpdate}
          handleUpdateMetadata={handleUpdateMetadata}
          handleResetMetadata={handleResetMetadata}
          handleGetAnswer={handleGetAnswer}
          handleGetAnswers={handleGetAnswers}
          handleCancel={handleCancel}
          handleBack={handleBack}
          isNextDisabled={isNextDisabled}
          enableNext={enableNext}
          disableNext={disableNext}
          handleSave={handleSave}
          isSaved={isSaved}
        >
          <GridWrapper
            matrix
            gutterSize="zero"
            className="ln-o-grid--gutter-zero@max-mobile"
          >
            {(isSaved() && !state.loading) &&  (
              <GridItem>
                <Alert className="s-page-alert" variant="success">
                  Your form has been saved. visit the <a onClick={() => navigateTo("/drafts")} style={{cursor: 'pointer', textDecoration: 'underline'}}>Drafts</a> page to view your saved forms.
                </Alert>
              </GridItem>
            )}
            {state.components?.map((componentProps:any) => (
              <GridItem
                key={`${state.serviceType}-${state.id}-${componentProps.id}`}
                size="1/1"
              >
                <ComponentSwitch
                  {...componentProps}
                  key={`${state.serviceType}-${state.id}`}
                />
              </GridItem>
            ))}
          </GridWrapper>
        </ComponentContextProvider>
      </ReportingErrorBoundary>
    </Container>
  )
}

export default ReportingPage