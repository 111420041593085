import { Card } from '@jsluna/card'
import {
  Title,
  IncidentNumber,
  Investigation,
  IncidentDescription,
  ReportedByDescription
} from '../Components'
import { IIncident } from '../../../types'
import PromptComponent from '../../Reporting/ComponentSwitch/Components/PromptComponent'
import IconComponent from '../../Reporting/ComponentSwitch/Components/IconComponent'
import { useState } from 'react'
import { useNavigate } from 'react-router'

const IncidentCard = ({ incident }: IIncident) => {
  const [showPrompt, setShowPrompt] = useState(false)

  const navigateTo = useNavigate()

  function createInvestigation() {
    if (sessionStorage.getItem('draftId')) {
      sessionStorage.removeItem('draftId')
    }
    if (sessionStorage.getItem('investigationId')) {
      sessionStorage.removeItem('investigationId')
    }
    sessionStorage.setItem('investigationId', incident.recordId.toString())
    navigateTo('/reporting')
  }

  function handlePromptResponse(response: boolean) {
    setShowPrompt(false)
    if (response) {
      createInvestigation()
    }
  }

  return (
    <>
      {showPrompt ? (
        <PromptComponent
          title={'Start an investigation'}
          body={'Do you want to start an investigation on this incident?'}
          text={'Start an investigation'}
          icon={<IconComponent value={'BlueInfoIcon'} />}
          classification=''
          handleResult={handlePromptResponse}
        />
      ) : null}
      <div className={incident.status === 'Investigation Required' ? 's-card-clickable' : ''} >
        <Card
          className="s-reported-accident-card rln-c-card ln-c-card--soft ln-u-margin-bottom*2 ln-u-margin-left"
          onClick={() => incident.status === 'Investigation Required' && setShowPrompt(true)}
        >
          <Title text={incident.category} />
          <IncidentNumber incidentNumber={incident.reference} />
          <Investigation status={incident.status} />
          <IncidentDescription
            dateOfIncident={incident.dateOfIncident}
            timeOfIncident={incident.timeOfIncident}
            location={incident.location}
            store={incident.store}
            type={'incident'}
          />
          <ReportedByDescription
            reportedBy={incident.reportedBy}
            dateReported={incident.dateReported}
            timeReported={incident.timeReported}
          />
        </Card>
    </div>
    </>
  )
}

export default IncidentCard
