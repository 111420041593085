import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

const Loading = message => (
  <div className="index-app-loading">
    <ProgressIndicator
      className="ln-u-justify-content-center"
      loading
      preventFocus
    >
      <div className="loading-message s-text-align-center">
        <ProgressSpinner size="medium" className="ln-u-push-right-sm" />
        {message.message}
      </div>
    </ProgressIndicator>
  </div>
)

export default Loading
