import { IDraftDetails } from '../types/Drafts/IDraftsTypes'

const getIncidentData = (draft: IDraftDetails) => {
  let incidentCategory
  let incidentDate
  let incidentTime
  let incidentLocation
  let incidentStore
  let incidentRef

  const incidentResponses = JSON.parse(draft.session.responseStorage) ?? null
  const investigationResponses = incidentResponses?.safety?.report
    ?.reportIncident
    ? JSON.parse(incidentResponses?.safety?.report?.reportIncident)
    : null

  switch (draft?.type) {
    case 'Investigation':
      incidentRef = investigationResponses?.Reference ?? null
      incidentCategory = investigationResponses?.Category ?? null
      incidentDate = investigationResponses?.DateOfIncident ?? null
      incidentTime = investigationResponses?.TimeOfIncident
        ? new Date(investigationResponses?.TimeOfIncident).toLocaleTimeString(
            [],
            { hour: '2-digit', minute: '2-digit', hour12: false }
          )
        : null
      incidentStore = investigationResponses.Store ?? null
      return {
        incidentRef,
        incidentCategory,
        incidentDate,
        incidentTime,
        incidentStore
      }
    default:
      incidentCategory =
        incidentResponses?.safety?.report?.incident_category_label ?? null
      incidentDate = incidentResponses?.safety?.report?.incident_date ?? null
      incidentTime = incidentResponses?.safety?.report?.incident_time ?? null
      incidentLocation =
        incidentResponses?.safety?.report?.incident_location_label ?? null
      incidentStore =
        incidentResponses?.safety?.report?.incident_store_label ?? null

      return {
        incidentCategory,
        incidentDate,
        incidentTime,
        incidentLocation,
        incidentStore
      }
  }
}

export default getIncidentData
