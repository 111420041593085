import { useNavigate } from 'react-router'
import { Card } from '@jsluna/card'
import { IDraft } from '../../../types/Drafts/IDraftsTypes'
import Title from '../Components/Title'
import IncidentDescription from '../Components/IncidentDescription'
import { useState } from 'react'
import PromptComponent from '../../Reporting/ComponentSwitch/Components/PromptComponent'
import IconComponent from '../../Reporting/ComponentSwitch/Components/IconComponent'
import getIncidentData from '../../../utils/getIncidentData'
import { IncidentNumber } from '../Components'

const DraftCard = ({ draft }: IDraft) => {
  const [showPrompt, setShowPrompt] = useState(false)
  const navigateTo = useNavigate()

  const responses = getIncidentData(draft)

  function continueSession() {
    sessionStorage.setItem('draftId', draft.id)
    navigateTo('/reporting')
  }

  function handlePromptResponse(response: boolean) {
    setShowPrompt(false)
    if (response) {
      continueSession()
    }
  }

  return (
    <>
      {showPrompt ? (
        <PromptComponent
          title={'Continue draft'}
          body={'Do you want to continue where you left off?'}
          text={'Continue'}
          icon={<IconComponent value={'BlueInfoIcon'} />}
          classification=""
          handleResult={handlePromptResponse}
        />
      ) : null}

      {draft.type && (
        <Card
          className="s-reported-accident-card rln-c-card ln-c-card--soft ln-u-margin-bottom*2 ln-u-margin-left s-card-clickable"
          onClick={() => setShowPrompt(true)}
        >
          <Title
            text={
              draft.type.replace('_', ' ') +
              ' - ' +
              (responses?.incidentCategory ?? 'Unknown category')
            }
          />
          {draft.type === 'Investigation' && (
            <IncidentNumber incidentNumber={responses?.incidentRef ?? null} />
          )}
          <IncidentDescription
            dateOfIncident={
              responses?.incidentDate ? new Date(responses?.incidentDate) : null
            }
            timeOfIncident={responses?.incidentTime ?? null}
            location={
              responses?.incidentLocation ? responses?.incidentLocation : null
            }
            store={responses?.incidentStore ? responses?.incidentStore : null}
            type={'draft'}
          />
        </Card>
      )}
    </>
  )
}

export default DraftCard
